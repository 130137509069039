import { GENESIS_URL } from "../urls/base-urls";
import axios from "../http-client/axios";

export async function saveCertificate(formData) {
  const resp = await axios.post(
    `${GENESIS_URL}/admin/certificate`,
    requestBuilder(
      formData,
      formData.files[0],
    )
  );

  return resp.data;
}


export async function updateCertificate(formData) {
  const resp = await axios.put(
    `${GENESIS_URL}/admin/certificate`,
    requestBuilder(
      formData,
      formData.files[0],
    )
  );

  return resp.data;
}


export async function getCertificatePreview(formData) {

  let formDataInstance = new FormData();
  formDataInstance.append("template", formData.certificateText);
  formDataInstance.append("file", formData.files[0]);

  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate-preview`,
    // url: `http://localhost:8303/admin/certificate-preview`,
    method: "post",
    data: formDataInstance,
    headers: { "content-type": "application/json" }, 
  });

  return resp.data;
}

function requestBuilder(formData, attachment) {
  const objectRequest = {
    name: formData.name,
    dateStarted: formData.dateStarted,
    dateFinished: formData.dateFinished,
    title: formData.title,
    text: formData.certificateText,
  };
  if (formData.id) objectRequest.idCertificate = formData.id

  let formRequest = new FormData();
  const blob = new Blob([JSON.stringify(objectRequest)],{
    type: 'application/json'
  });

  formRequest.append("request", blob);
  formRequest.append("file", attachment)
  return formRequest
}

export async function getTrainings() {
  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate/training`,
    // url: `http://localhost:8303/admin/certificate/training`,

    method: "get",
    data: {},
    headers: {
      "content-type": "application/json",
    },
  });

  return resp.data;  
}

export async function deleteLink(id) {
  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate-link?id=${id}`,
    // url: `http://localhost:8303/admin/certificate/training`,

    method: "delete",
    data: {},
    headers: {
      "content-type": "application/json",
    },
  });

  return resp.data;  
}

export async function getLinkedCertificates(idTraining) {
  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate/linked-training?idTraining=${idTraining}`,
    // url: `http://localhost:8303/admin/certificate/linked-training?idTraining=${idTraining}`,

    method: "get",
    data: {},
    headers: {
      "content-type": "application/json",
    },
  });

  return formatLinkedCertificates(resp.data);  
}

function formatLinkedCertificates(data) { 
  const obj = {
    warn: null,
    error: null,
  }

  data.forEach(cert => { 
    cert.statusActivation = null;
    cert.status = cert.status == 'ENABLED' ? true : false; 
    cert.startDateObj = obj;
    cert.endDateObj = obj;
  })

  return data;
}

export async function getRulesDataByTraining(request) {
  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate/validate-rules-training`,
    // url: `http://localhost:8303/admin/certificate/validate-rules-training`,

    method: "post",
    data: request,
    headers: {
      "content-type": "application/json",
    },
  });

  return resp.data;  
}

export async function getRulesDataByCertificates(request) {
  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate/validate-rules`,
    // url: `http://localhost:8303/admin/certificate/validate-rules`,

    method: "post",
    data: request,
    headers: {
      "content-type": "application/json",
    },
  });

  return resp.data;  
}


export async function getLinkedTrainings(idCertificate) {
  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate/trainings-linked?idCertificate=${idCertificate}`,
    method: "get",
    headers: {
      "content-type": "application/json",
    },
  });

  resp.data.forEach(link => { 
    link.statusBoolean = link.status == 'ENABLED'; 
  })
  return resp.data;  
}



export async function getCertificate(idCertificate) {
  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate?idCertificate=${idCertificate}`,
    method: "get",
    headers: {
      "content-type": "application/json",
    },
  });
  return resp.data;  
}

export async function saveLinkCertificateTraining(formData) {

  const request = {
    idLinkCertificateTraining: formData.idLinkCertificateTraining,
    idTraining: formData.idTraining,
    idCertificate: formData.idCertificate,
    startDate: formData.dateStarted,
    endDate: formData.dateFinished,
    status: formData.status,
  }

  const resp = await axios({
    url: `${GENESIS_URL}/admin/certificate/save-link-training`,
    // url: `http://localhost:8303/admin/certificate/save-link-training`,

    method: "post",
    data: request,
    headers: {
      "content-type": "application/json",
    },
  });

  return resp.data;  
}
